import React, { useState, useRef, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import "./style.css";

const VideoSection: React.FC = () => {
  const [playButton, setPlayButton] = useState<boolean>(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [controlsVisible, setControlsVisible] = useState<boolean>(true);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleCanPlay = () => {
      setIsVideoPlaying(true);
    };

    video.addEventListener("canplay", handleCanPlay);
    return () => {
      video.removeEventListener("canplay", handleCanPlay);
    };
  }, []);

  const handlePlayButtonClick = () => {
    const video = videoRef.current;
    if (!video) return;

    if (playButton) {
      video.play();
      // Esconde os controles após dar play
      setControlsVisible(false);
    } else {
      video.pause();
      // Mostra os controles ao pausar
      setControlsVisible(true);
    }
    setPlayButton(!playButton);
  };

  const handleFullscreenClick = () => {
    const video = videoRef.current;
    if (!video) return;

    if (!document.fullscreenElement) {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if ((video as any).mozRequestFullScreen) {
        (video as any).mozRequestFullScreen();
      } else if ((video as any).webkitRequestFullscreen) {
        (video as any).webkitRequestFullscreen();
      } else if ((video as any).msRequestFullscreen) {
        (video as any).msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) {
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  const handleMouseEnter = () => {
    // Ao passar o mouse, mostra os controles se o vídeo estiver rodando
    if (!playButton) {
      setControlsVisible(true);
    }
  };

  const handleMouseLeave = () => {
    // Ao sair com o mouse, esconde os controles se o vídeo estiver rodando
    if (!playButton) {
      setControlsVisible(false);
    }
  };

  return (
    <Box component="section" className="video-section">
      <Box
        className="pre-video"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <IconButton
          onClick={handlePlayButtonClick}
          className={`play-button control ${controlsVisible ? "" : "hidden"}`}
        >
          {playButton ? (
            <PlayArrowIcon fontSize="large" style={{ color: "black" }} />
          ) : (
            <PauseIcon fontSize="large" style={{ color: "black" }} />
          )}
        </IconButton>
        <IconButton
          onClick={handleFullscreenClick}
          className={`fullscreen-button control ${controlsVisible ? "" : "hidden"}`}
        >
          {isFullscreen ? (
            <FullscreenExitIcon fontSize="large" style={{ color: "black" }} />
          ) : (
            <FullscreenIcon fontSize="large" style={{ color: "black" }} />
          )}
        </IconButton>
        <Box
          component="video"
          autoPlay
          loop
          muted={!isVideoPlaying}
          ref={videoRef}
          className="background-video"
          poster={`${process.env.PUBLIC_URL}/assets/images/any/video.png`}
        >
          <source
            src={`${process.env.PUBLIC_URL}/assets/video/manifesto.mp4`}
            type="video/mp4"
          />
          Seu navegador não suporta o vídeo.
        </Box>
      </Box>
    </Box>
  );
};

export default VideoSection;